import React, { useEffect, useState } from 'react'
import { IoDocumentTextOutline } from "react-icons/io5";
import { LiaBriefcaseSolid } from "react-icons/lia";
import { PiExamLight } from "react-icons/pi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';

import { FaHamburger } from 'react-icons/fa';
export default function SidebarBeta() {
    const navigator=useNavigate()
    const [sidebarActive,setSidebarActive]=useState(false)
    if(!sidebarActive && window.screen.width<600){
        return(
          <aside id="default-sidebar" class="fixed top-0 left-0 z-10 h-screen " aria-label="Sidenav">
            <button onClick={()=>setSidebarActive(true)} variant="outline" className="fixed top-4 left-0 p-2 border shadow-md bg-white border-gray-200">
              <FaHamburger width={16} height={16}/>
            </button>
          </aside>
      )
      }
  return (
    <div className="hidden fixed md:flex flex-col justify-between top-0 left-0 w-60 h-screen bg-white p-4 pt-12 shadow-lg">
        <div>
            <div className='mb-4'>
                <img src="https://i.postimg.cc/x1CsY9DH/1x1-Images-28.png" className='w-12 h-12'/>
                <h2 className='font-semibold text-2xl'>superattorney.ai</h2>
                <p className='text-sm'>Your helping hand in legal tasks</p>
            </div>
            <div className='py-8 w-full'>
                <motion.button onClick={()=>navigator("/markdown")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><TbDeviceAnalytics className='mr-4' size={32}/>Relevant Case (A)</motion.button> 
                <motion.button onClick={()=>navigator("/json1")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><IoDocumentTextOutline className='mr-4' size={32}/>Relevant Case (B)</motion.button> 
                <motion.button onClick={()=>navigator("/json2")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><IoDocumentTextOutline className='mr-4' size={32}/>Relevant Case (C)</motion.button> 
                
            </div>
        </div>
        <div>
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-md font-semibold hover:bg-gray-100 hover:border-primary-500'><CgProfile className='mr-4' size={32}/>My Profile</motion.button> 
        </div>
    </div>
  )
}
