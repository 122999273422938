import React, { useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FaAngleDown } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import CaseChatter from "./CaseChatter";
import { CgClose } from "react-icons/cg";

const renderJson = (data) => {
  return Object.entries(data).map(([key, value]) => {
    if (typeof value === "string") {
      return (
        <div key={key} className="mb-2">
          <span className="text-xs sm:text-sm font-semibold text-primary-900">{key}:</span>
          <hr className="mb-2" />
          <p className="text-gray-700 text-xs sm:text-sm ml-4 sm:ml-8">{value}</p>
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div key={key} className="mb-2">
          <span className="font-semibold text-xs sm:text-sm text-primary-900">{key}:</span>
          <hr className="mb-2" />
          <ul className="list-disc list-inside ml-2 sm:ml-4">
            {value.map((item, index) => (
              <li key={index} className="text-gray-700 text-xs sm:text-sm">
                {typeof item != "object" && item}
                {typeof item === "object" && renderJson(item)}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === "object") {
      return (
        <div>
          <span className="text-xs sm:text-sm font-semibold text-primary-900">
            {key}:
          </span>
          <hr className="mb-2" />
          <div className="ml-4 sm:ml-8">{renderJson(value)}</div>
        </div>
      );
    }
    return null;
  });
};

export default function PDFCardJSONWhole({ data, idx }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [showDetail, setShowDetail] = useState(false);
  const [active, setActive] = useState("summary");
  const [showSummaryDetail, setShowSummaryDetail] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const content = JSON.parse(data.content);

  return (
    <div className="w-full px-2 sm:px-4">
      <div onClick={() => setShowDetail(true)} className="w-full my-2 bg-white p-3 sm:p-4 md:p-6 flex items-center justify-between cursor-pointer hover:bg-purple-100">
        <div className="flex items-center">
          <a
            target="_blank"
            className="p-1 sm:p-2 rounded border hover:bg-gray-200"
            href={data.pdf_link}
          >
            📒
          </a>
          <div className="flex flex-col ml-2">
            <p className="text-xs sm:text-sm font-semibold">{content["Case Title"]}</p>
            <p className="text-xs text-gray-700">
              Contains the full case in this PDF
            </p>
            <p className="text-xs text-gray-700">
              [Click on the 📒 icon to download]
            </p>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showDetail && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white w-[95vw] sm:w-[90vw] h-[95vh] sm:h-[90vh] rounded-lg shadow-lg overflow-hidden flex flex-col"
            >
              <div className="p-3 sm:p-4 flex justify-between items-center bg-primary-900 text-white">
                <h2 className="text-sm sm:text-base md:text-xl font-bold">{content["Case Title"]}</h2>
                <button
                  onClick={() => setShowDetail(false)}
                  className="text-white hover:text-gray-300"
                >
                  <CgClose size={20} />
                </button>
              </div>

              <div className="p-2 sm:p-4 flex-grow overflow-y-auto">
                <div className="flex items-center justify-center space-x-1 sm:space-x-2 mb-3 sm:mb-4">
                  <div className="grid grid-cols-3 gap-1 sm:gap-2">
                    {["summary", "pdf", "question"].map((tab) => (
                      <button
                        key={tab}
                        onClick={() => setActive(tab)}
                        className={`h-full font-semibold text-xs sm:text-sm text-center border rounded px-1 sm:px-2 md:px-4 py-1 sm:py-2 ${
                          active === tab
                            ? "border-primary-900 bg-primary-900 text-primary-50"
                            : "border-primary-900 bg-primary-50 text-primary-900"
                        }`}
                      >
                        {tab === "summary"
                          ? "Summary"
                          : tab === "pdf"
                          ? "Read PDF"
                          : "Ask Questions"}
                      </button>
                    ))}
                  </div>
                </div>

                {active === "summary" && (
                  <div className="bg-white rounded shadow p-2 sm:p-4">
                    <p className="font-semibold text-sm sm:text-base mb-2 sm:mb-4 w-full bg-primary-50 text-primary-900 px-2 sm:px-4 py-1 sm:py-2">
                      Case Title
                    </p>
                    <div className="px-2 sm:px-4 text-xs sm:text-sm mb-2">
                      {content["Case Title"]}
                    </div>
                    <p className="font-semibold text-sm sm:text-base mb-2 sm:mb-4 w-full bg-primary-50 text-primary-900 px-2 sm:px-4 py-1 sm:py-2">
                      Summary
                    </p>
                    <div className="px-2 sm:px-4">{renderJson(content["Summary"])}</div>
                    {!showSummaryDetail ? (
                      <button
                        onClick={() => setShowSummaryDetail(true)}
                        className="mx-auto text-xs font-semibold text-gray-200 px-3 sm:px-4 py-1 sm:py-2 my-2 sm:my-4 bg-primary-500 hover:bg-primary-600"
                      >
                        More Details
                      </button>
                    ) : (
                      <>
                        {Object.entries(content).map(([key, value]) => {
                          if (key === "Summary" || key === "Case Title") return null;
                          return (
                            <div key={key}>
                              <p
                                onClick={() => setActiveKey(key)}
                                className="font-semibold text-sm sm:text-base mb-2 sm:mb-4 w-full bg-primary-50 text-primary-900 px-2 sm:px-4 py-1 sm:py-2"
                              >
                                {key}
                              </p>
                              {typeof value === "string" ? (
                                <p className="text-xs sm:text-sm px-2 sm:px-4">{value}</p>
                              ) : (
                                <div className="px-2 sm:px-4">{renderJson(value)}</div>
                              )}
                            </div>
                          );
                        })}
                        <button
                          onClick={() => setShowSummaryDetail(false)}
                          className="mx-auto text-xs font-semibold text-gray-200 px-3 sm:px-4 py-1 sm:py-2 my-2 sm:my-4 bg-primary-500 hover:bg-primary-600"
                        >
                          Less Details
                        </button>
                      </>
                    )}
                  </div>
                )}

                {active === "question" && <CaseChatter caseJson={content} />}

                {active === "pdf" && (
                  <div className="w-full h-full bg-white rounded shadow p-2 sm:p-4">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={data.pdf_link}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}