import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import apiService from "../apiService";

export default function CaseSummary() {
  const location = useLocation();
  const { caseElement } = location.state || {};
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showPreview, setShowPreview] = useState(true);

  useEffect(() => {
    fetchSummary();
  }, []);

  const fetchSummary = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(`case-summaries/case-element/${caseElement.id}/summary-or-create`)
      setSummary(response["summary"]);
    } catch (error) {
      console.error("Error fetching summary:", error);
      toast.error("Failed to load summary");
    } finally {
      setLoading(false);
    }
  };

  const handleSummaryChange = (content) => {
    setSummary(content);
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const payload = {
        summary: summary,
      };
      const response = await apiService.put(`case-summaries/case-element/${caseElement.id}`, payload)
      console.log("Saving summary:", payload);
      toast.success("Summary saved successfully");
    } catch (error) {
      console.error("Error saving summary:", error);
      toast.error("Failed to save summary");
    } finally {
      setShowPreview(true)
      setSaving(false);
    }
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-900"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full mx-auto p-4">
      
      <div className="flex-grow h-full overflow-auto mb-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-purple-900">Case Summary</h2>
        {!showPreview && <button
          onClick={handleSubmit}
          disabled={saving}
          className={`bg-purple-900 text-white px-4 py-2 rounded hover:bg-purple-800 transition-colors ${
            saving ? 'opacity-50 cursor-not-allowed text-sm sm:text-base' : ''
          }`}
        >
          {saving ? (
            <>
              <span className="inline-block animate-spin mr-2">⌛</span>
              Saving...
            </>
          ) : (
            'Save Summary'
          )}
        </button>}
        {showPreview && <button
          onClick={()=>setShowPreview(false)}
          className=" text-sm sm:text-base bg-purple-900 text-white px-4 py-2 rounded hover:bg-purple-800 transition-colors"
        >
          Edit
        </button>}
      </div>
        {!showPreview ? (
          <ReactQuill
            theme="snow"
            value={summary}
            onChange={handleSummaryChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image'],
                ['clean'],
              ],
            }}
            className="h-4/5"
          />
        ) : (
          <div className="ql-snow h-4/5 overflow-auto">
           
            <div className="ql-editor p-4 text-xs md:text-sm">
              <div dangerouslySetInnerHTML={{ __html: summary }} />
            </div>
          </div>
        )}
      </div>
      
    </div>
  );
}