import React,{useState,useEffect,useRef} from 'react'
import SidebarLarge from '../Components/SidebarLarge';
import Navbar from '../Components/Navbar';
import {motion} from 'framer-motion'

import { CgAdd } from 'react-icons/cg';
import { IoIosArrowRoundUp  } from "react-icons/io";
import axios from 'axios';
import { SERVER_URL } from '../Data/apiData';
import PDFCardMarkdown from '../Components/CaseStudies/PDFCardMarkdown';
import SidebarBeta from '../Components/SidebarBeta';
export default function CaseStudiesMarkdown() {
    const textareaRef = useRef(null);
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState(null)
    const [input,setInput]=useState("")
    const findRelevantMarkdown=async()=>{
        setInput("")
        setLoading(true)
        console.log("HELP")
        const res=await axios.post(SERVER_URL+"/case_query/get_similar",{
            "query":input
        })
        console.log(res.data)
        setData(res.data)
        setLoading(false)
    }
    const findRelevantJSON=async()=>{
        setInput("")
        setLoading(true)
        //console.log("HELP")
        const res=await axios.post(SERVER_URL+"/case_query/get_similar_json",{
            "query":input
        })
        console.log(res.data)
        setData(res.data)
        setLoading(false)
    }
    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }, [input]);
  return (
    <div className='w-screen h-screen p-8 md:pl-64 pt-24'>
        <SidebarBeta active="case-studies"/>
        <Navbar/>
        <div className='flex w-full mb-4'>
            {/* <motion.button className='p-1 rounded-full text-gray-800 hover:text-indigo-500'>
                <CgAdd size={24} />
            </motion.button> */}
            
             <textarea
                ref={textareaRef}
                value={input}
                onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                        e.preventDefault()
                        findRelevantMarkdown()
                        setInput("")
                    }
                }}
                onChange={(e)=>setInput(e.target.value)}
                placeholder='✨ Tell me about the case you want to find'
                className='w-full text-sm overflow-hidden resize-none border-2 focus:border-indigo-500 border-gray-300  focus:outline-indigo-500 outline-none p-2'
                rows={1}
                style={{outline: 'none'}}
            />
            {/* <textarea 
                onKeyDown={(e)=>{
                    if(e.key=="Enter"){
                        findRelevant()
                    }
                }}
                value={input} 
                onChange={(e)=>setInput(e.target.value)} 
                placeholder='Tell me about the case ✨' 
                className='h-16 w-full shadow-md border border-gray-300 rounded-lg focus:border-indigo-500 focus:border-b-2 active:border-0 focus:border-0 outline-none px-8'/> */}
            
            <motion.button onClick={findRelevantMarkdown} className='p-2 ml-1 hover:text-white hover:bg-indigo-500 bg-gray-100'>
                <IoIosArrowRoundUp size={24} />
            </motion.button>
        </div>
        <div className='relative overflow-auto h-[85vh] rounded-xl bg-gray-100'>
          {data!=null && !loading && <div>
            <div className='w-full flex flex-col items-start p-4 pb-2'>
            <span className='p-2'>📌</span><p className='text-md text-wrap'>Here are some of the relevant cases that I could find based on your case description.</p>    
            </div>
            {data.map((a,idx)=>(<PDFCardMarkdown data={a} idx={idx}/>))}
          </div>}
          {loading && <p>Finding Relevant Cases...</p>}
      </div>
      
        
      
    </div>
  )
}
