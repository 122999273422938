import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaAngleLeft } from "react-icons/fa6";
import CaseFiles from "../Components/CaseOrganization/CaseFiles";
import CaseDates from "../Components/CaseOrganization/CaseDates";
import CaseSummary from "../Components/CaseOrganization/CaseSummary";
import SidebarLarge from "../Components/SidebarLarge";
import Navbar from "../Components/Navbar";

export default function CaseElementPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const caseDetails = location.state?.caseElement;
  const [activeTab, setActiveTab] = useState("summary");
  const { id } = useParams();

  const tabs = [
    { id: "summary", label: "Summary" },
    { id: "files", label: "Files" },
    { id: "dates", label: "Dates" },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "summary":
        return <CaseSummary caseId={id} />;
      case "files":
        return <CaseFiles caseId={id} />;
      case "dates":
        return <CaseDates caseId={id} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 md:pl-64 pt-20 ">
      <SidebarLarge active="organization" />
      <Navbar/>
      <main className="p-6 md:p-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8"
        >
          <div className="flex items-center mb-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate(-1)}
              className="p-2 mr-4 text-primary-900 hover:text-white hover:bg-primary-900 rounded-full transition-colors duration-300"
            >
              <FaAngleLeft size={24} />
            </motion.button>
            <h2 className="  text-xl md:text-3xl font-bold text-gray-800">
              {caseDetails.name}
            </h2>
          </div>

          <div className="flex justify-between items-center">
            <h3 className="text-sm text-gray-600">
              Manage the details of your case element
            </h3>
            {/* <button className="text-2xl text-gray-500 hover:text-gray-700 transition-colors">
              <IoInformationCircleOutline />
            </button> */}
          </div>
        </motion.div>

        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <nav className="flex border-b border-gray-200" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`${
                  activeTab === tab.id
                    ? "border-primary-900 text-primary-900"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } flex-1 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-300`}
              >
                {tab.label}
              </button>
            ))}
          </nav>

          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="p-6"
            >
              {renderTabContent()}
            </motion.div>
          </AnimatePresence>
        </div>
      </main>
    </div>
  );
}