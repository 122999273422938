import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import CaseStudiesMarkdown from "./Pages/CaseStudiesMarkdown";
import CaseStudiesJSON from "./Pages/CaseStudiesJSON";
import DraftCreator from "./Pages/DraftCreator";
import ActFinder from "./Pages/ActFinder";
import Chatter from "./Pages/Chatter";
import CaseOrganization from "./Pages/CaseOrganization";
import CaseElementPage from "./Pages/CaseElement";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
        <Toaster/>
        <Routes>
          <Route path="/" element={<CaseStudiesJSON />} />
          <Route path="/draft" element={<DraftCreator />} />
          <Route path="/markdown" element={<CaseStudiesMarkdown />} />
          <Route path="/acts" element={<ActFinder />} />
          <Route path="/chatter" element={<Chatter />} />
          <Route path="/case-studies" element={<CaseStudiesJSON />} />
          <Route path="/case-organization" element={<CaseOrganization />} />
          <Route path="/case-element/:id" element={<CaseElementPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
