import React from 'react'

export default function ActCard({data}) {
  return (
    <div className='w-full flex flex-col items-start bg-white p-4 rounded shadow'>
        <h4 className='text-xs px-2 py-1 bg-purple-900 rounded font-semibold text-white'>{data.act}</h4>
        <h4 className='font-semibold text-lg'>{data.title}</h4>
        <p className='text-sm text-gray-500'>{data.subtitle}</p>
        <hr className='my-2 text-gray-500'/>

        <div className='p-4 text-sm border shadow w-full'>
            <h5 className='font-semibold'>{data.object.subject}</h5>
            <p>{data.object.content}</p>
        </div>
        
      
    </div>
  )
}
