import { supabase } from "../supabaseClient"
export const createQusetion=async(data)=>{
    const res=await supabase
    .from('superattorney_chat') 
    .insert([{
        "question":data.question,
        "type":data.type
       
     }]).select();
    console.log(res.data)
    return res.data
}