import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FiPlus, FiEdit2 } from "react-icons/fi";
import apiService from "../Components/apiService";
import SidebarLarge from "../Components/SidebarLarge";
import Navbar from "../Components/Navbar";

export default function CaseOrganization() {
  const [caseElements, setCaseElements] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCaseName, setNewCaseName] = useState("");
  const [editingCase, setEditingCase] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const userId = "9eb43bc4-e485-413e-b453-b4ae0019e88f";
  const navigate = useNavigate();

  useEffect(() => {
    fetchCaseElements();
  }, []);

  const fetchCaseElements = async () => {
    setIsFetching(true);
    try {
      const response = await apiService.get(`case-elements/user/${userId}`);
      if (response) {
        setCaseElements(response);
      }
    } catch (error) {
      toast.error("Failed to fetch case elements. Please try again.");
    } finally {
      setIsFetching(false);
    }
  };

  const handleCreateCaseElement = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const payload = { name: newCaseName, user_id: userId };
      const response = await apiService.post("case-elements", payload);
      setCaseElements([...caseElements, response]);
      setShowCreateModal(false);
      setNewCaseName("");
      toast.success("Case element created successfully!");
    } catch (error) {
      toast.error("Failed to create case element. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditCaseElement = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const payload = { name: editingCase.name, status: editingCase.status };
      await apiService.put(`case-elements/${editingCase.id}`, payload);
      const updatedCaseElements = caseElements.map((caseElement) =>
        caseElement.id === editingCase.id ? editingCase : caseElement
      );
      setCaseElements(updatedCaseElements);
      setShowEditModal(false);
      setEditingCase(null);
      toast.success("Case element updated successfully!");
    } catch (error) {
      toast.error("Failed to update case element. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-green-100 text-green-800";
      case "closed":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-8 md:pl-64 pt-20">
      <Toaster position="top-right" />
      <SidebarLarge active="organization"/>
      <Navbar/>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mb-6 sm:mb-8"
      >
        <div className="relative">
          {/* <button className="text-2xl sm:text-3xl absolute top-0 right-0 text-gray-500 hover:text-gray-700 transition-colors">
            <IoInformationCircleOutline />
          </button> */}
          <h3 className="font-bold text-xl md:text-3xl text-gray-800">
            Case <span className="text-primary-900">Organization 📄</span>
          </h3>
          <p className="text-gray-600  text-sm mt-2">
            Organize all the details related to your cases in one place
          </p>
        </div>
      </motion.div>

      <div className="mb-4 sm:mb-6 flex justify-between items-center">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">My Case Folders</h2>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowCreateModal(true)}
          className="bg-primary-900 hover:bg-primary-800 text-white font-bold py-2 px-3 sm:px-4 rounded-lg shadow-md transition-colors duration-300 flex items-center text-sm sm:text-base"
        >
          <FiPlus className="mr-1 sm:mr-2" />
          Add Case Folder
        </motion.button>
      </div>

      <AnimatePresence>
        {isFetching ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="col-span-full p-4 text-center text-gray-600 text-sm sm:text-base"
          >
            Loading case elements...
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
          >
            {caseElements.map((caseElement) => (
              <motion.div
                key={caseElement.id}
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                whileHover={{ y: -5, boxShadow: "0 4px 20px rgba(0,0,0,0.1)" }}
                className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 relative"
              >
                <div
                  className="p-4 sm:p-6 cursor-pointer"
                  onClick={() =>
                    navigate(`/case-element/${caseElement.id}`, {
                      state: { caseElement },
                    })
                  }
                >
                  <span
                    className={`px-2 sm:px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(
                      caseElement.status
                    )}`}
                  >
                    {caseElement.status}
                  </span>
                  <h3 className="font-semibold text-lg sm:text-xl mt-2 sm:mt-3 text-gray-800">
                    {caseElement.name}
                  </h3>
                </div>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingCase(caseElement);
                    setShowEditModal(true);
                  }}
                  className="absolute top-3 sm:top-4 right-3 sm:right-4 text-primary-900 p-1 sm:p-2 rounded-full hover:bg-purple-100 transition-colors duration-300"
                >
                  <FiEdit2 size={16} />
                </motion.button>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Create Modal */}
      <AnimatePresence>
        {showCreateModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white p-6 sm:p-8 rounded-2xl shadow-xl w-full max-w-sm"
            >
              <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4 sm:mb-6">
                Create New Case Element
              </h3>
              <form onSubmit={handleCreateCaseElement}>
                <div className="mb-4 sm:mb-6">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="caseName"
                  >
                    Case Name
                  </label>
                  <input
                    type="text"
                    id="caseName"
                    value={newCaseName}
                    onChange={(e) => setNewCaseName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent text-sm sm:text-base"
                    required
                  />
                </div>
                <div className="flex items-center justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setShowCreateModal(false)}
                    className="text-gray-600 hover:text-gray-800 font-semibold text-sm sm:text-base"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="bg-primary-900 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded-lg shadow-md transition-colors duration-300 text-sm sm:text-base"
                    disabled={isLoading}
                  >
                    {isLoading ? "Creating..." : "Create"}
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Edit Modal */}
      <AnimatePresence>
        {showEditModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white p-6 sm:p-8 rounded-2xl shadow-xl w-full max-w-sm"
            >
              <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4 sm:mb-6">
                Edit Case Element
              </h3>
              <form onSubmit={handleEditCaseElement}>
                <div className="mb-4 sm:mb-6">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="editCaseName"
                  >
                    Case Name
                  </label>
                  <input
                    type="text"
                    id="editCaseName"
                    value={editingCase?.name || ""}
                    onChange={(e) =>
                      setEditingCase({ ...editingCase, name: e.target.value })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent text-sm sm:text-base"
                    required
                  />
                </div>
                <div className="mb-4 sm:mb-6">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="editCaseStatus"
                  >
                    Status
                  </label>
                  <select
                    id="editCaseStatus"
                    value={editingCase?.status || ""}
                    onChange={(e) =>
                      setEditingCase({ ...editingCase, status: e.target.value })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent text-sm sm:text-base"
                    required
                  >
                    <option value="Active">Active</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
                <div className="flex items-center justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setShowEditModal(false)}
                    className="text-gray-600 hover:text-gray-800 font-semibold text-sm sm:text-base"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="bg-primary-900 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded-lg shadow-md transition-colors duration-300 text-sm sm:text-base"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}