export const lawTypes = {
    "Corporate Law": 
    [
        "Articles of Incorporation",
        "Shareholder Agreements", 
        "Merger and Acquisition Contracts", 
        "Non-Disclosure Agreements"
    ],
    "Criminal Defense Law": 
    [
        "Motion to Dismiss", 
        "Plea Agreements", 
        "Bail Applications", 
        "Appeals"
    ],
    "Family Law": 
    [
        "Divorce Petitions", 
        "Child Custody Agreements", 
        "Prenuptial Agreements", 
        "Adoption Papers"
    ],
    "Personal Injury Law": 
    [
        "Demand Letters", 
        "Settlement Agreements", 
        "Complaints", 
        "Medical Release Forms"
    ],
    "Estate Planning Law": 
    [
        "Wills", 
        "Trusts", 
        "Power of Attorney", 
        "Living Wills"
    ],
    "Intellectual Property Law": 
    [
        "Patent Applications", 
        "Trademark Registrations", 
        "Copyright Registrations", 
        "Licensing Agreements"
    ],
    "Employment Law": 
    [
        "Employment Contracts", 
        "Severance Agreements", 
        "Non-Compete Agreements", 
        "Discrimination Complaints"
    ],
    "Tax Law": 
    [
        "Tax Returns", 
        "IRS Appeals", 
        "Tax Opinion Letters", 
        "Offshore Disclosure Forms"
    ],
    "Immigration Law": 
    [
        "Visa Applications", 
        "Green Card Applications", 
        "Citizenship Applications", 
        "Asylum Petitions"
    ],
    "Real Estate Law": 
    [
        "Purchase Agreements", 
        "Lease Agreements", 
        "Mortgage Documents", 
        "Property Deeds"
    ]
}
export const documentInformation = {
    "Articles of Incorporation": {
        "Corporate Information": [
          "Company Name",
          "Business Purpose",
          "Principal Office Address",
          "Corporate Duration",
          "Fiscal Year End Date"
        ],
        "Registered Agent": [
          "Registered Agent Name and Address"
        ],
        "Incorporators": [
          "Incorporator Names and Addresses",
          "Incorporator Signatures"
        ],
        "Share Structure": [
          "Number of Authorized Shares",
          "Classes of Shares",
          "Par Value of Shares",
          "Restrictions on Transfer of Shares",
          "Provisions for Preemptive Rights"
        ],
        "Management": [
          "Names and Addresses of Initial Directors",
          "Provisions for Cumulative Voting"
        ],
        "Legal Requirements": [
          "Filing Date",
          "State of Incorporation"
        ]
      },
      "Shareholder Agreement":{
        "Company Information": [
          "Company Name",
          "Company Address",
          "Date of Agreement"
        ],
        "Shareholder Details": [
          "Names and Addresses of All Shareholders",
          "Number of Shares Held by Each Shareholder",
          "Classes of Shares"
        ],
        "Governance": [
          "Voting Rights",
          "Board Composition and Election Procedures"
        ],
        "Financial Provisions": [
          "Dividend Policy",
          "Valuation Method for Shares"
        ],
        "Share Transfer and Ownership": [
          "Share Transfer Restrictions",
          "Right of First Refusal",
          "Tag-Along Rights",
          "Drag-Along Rights",
          "Preemptive Rights"
        ],
        "Shareholder Protection": [
          "Non-Compete and Non-Solicitation Clauses",
          "Confidentiality Provisions"
        ],
        "Conflict Resolution": [
          "Dispute Resolution Mechanisms"
        ],
        "Exit and Succession": [
          "Exit Strategy",
          "Death or Incapacity Provisions"
        ],
        "Agreement Management": [
          "Amendments to the Agreement",
          "Termination of the Agreement"
        ],
        "Legal Framework": [
          "Governing Law"
        ],
        "Execution": [
          "Signatures of All Shareholders"
        ]
      },
      "Merger and Acquisition Contracts":{
        "Party Information": [
          "Parties Involved (Buyer and Seller)"
        ],
        "Transaction Structure": [
          "Transaction Structure (Merger, Asset Purchase, Stock Purchase)"
        ],
        "Financial Terms": [
          "Purchase Price",
          "Payment Terms",
          "Earnout Provisions",
          "Break-up Fees",
          "Post-Closing Adjustments"
        ],
        "Assets and Liabilities": [
          "Assets/Liabilities Included in the Deal",
          "Excluded Assets/Liabilities"
        ],
        "Due Diligence": [
          "Due Diligence Requirements"
        ],
        "Representations and Warranties": [
          "Representations and Warranties of Seller",
          "Representations and Warranties of Buyer"
        ],
        "Closing Conditions": [
          "Conditions to Closing",
          "Closing Date and Location"
        ],
        "Covenants": [
          "Covenants (Pre-Closing and Post-Closing)",
          "Non-Compete Clauses"
        ],
        "Employee Matters": [
          "Employee Matters"
        ],
        "Intellectual Property": [
          "Intellectual Property Transfer"
        ],
        "Indemnification": [
          "Indemnification Provisions"
        ],
        "Termination": [
          "Termination Rights"
        ],
        "Legal Framework": [
          "Governing Law and Jurisdiction",
          "Dispute Resolution Mechanism"
        ],
        "Confidentiality": [
          "Confidentiality Agreement"
        ],
        "Risk Allocation": [
          "Material Adverse Change Clause"
        ],
        "Regulatory Compliance": [
          "Required Regulatory Approvals"
        ],
        "Tax Considerations": [
          "Tax Considerations"
        ],
        "Transition": [
          "Transition Services Agreement"
        ],
        "Execution": [
          "Signatures of Authorized Representatives"
        ]
      },
      "Non-Disclosure Agreements":{
        "Party Information": [
            "Parties Involved (Disclosing Party and Receiving Party)",
            "Date of Agreement"
        ],
        "Scope and Purpose": [
            "Purpose of Disclosure",
            "Definition of Confidential Information",
            "Exclusions from Confidential Information"
        ],
        "Obligations and Restrictions": [
            "Obligations of Receiving Party",
            "Permitted Uses of Confidential Information",
            "No Grant of Rights or Licenses",
            "No Obligation to Disclose",
            "No Warranty Regarding Information"
        ],
        "Duration and Termination": [
            "Term of Agreement",
            "Survival Period of Confidentiality Obligations",
            "Return or Destruction of Confidential Information"
        ],
        "Legal Enforcement": [
            "Remedies for Breach",
            "Governing Law and Jurisdiction",
            "Dispute Resolution Mechanism"
        ],
        "General Provisions": [
            "Entire Agreement Clause",
            "Severability Clause",
            "Amendment Provisions",
            "Assignment Restrictions",
            "Waiver Provisions",
            "Notice Requirements"
        ],
        "Execution": [
            "Signatures of Authorized Representatives"
        ]
      },
      "Motion to Dismiss":{
        "Case Identification": [
            "Case Number",
            "Court Name and Jurisdiction",
            "Plaintiff's Name",
            "Defendant's Name",
            "Attorney Information (for both parties)"
        ],
        "Motion Header": [
            "Title of the Motion",
            "Introduction/Preliminary Statement"
        ],
        "Factual Background": [
            "Statement of Facts",
            "Procedural History"
        ],
        "Legal Basis": [
            "Legal Standard for Motion to Dismiss",
            "Grounds for Dismissal",
            "Legal Arguments Supporting Dismissal",
            "Cited Case Law and Statutes"
        ],
        "Relief Requested": [
            "Relief Sought",
            "Conclusion"
        ],
        "Authentication": [
            "Signature of Attorney",
            "Date of Filing"
        ],
        "Service and Filing": [
            "Certificate of Service"
        ],
        "Supporting Documents": [
            "Exhibits (if any)",
            "Proposed Order",
            "Affidavit or Declaration (if required)",
            "Memorandum of Law (if separate from motion)"
        ],
        "Additional Requests": [
            "Request for Oral Argument (if applicable)"
        ]
      }, 
      "Plea Agreements":{
        "Case Identification": [
            "Case Number",
            "Court Name and Jurisdiction",
            "Defendant's Full Name",
            "Prosecutor's Name",
            "Defense Attorney's Name"
        ],
        "Plea Details": [
            "Charges Being Pled To",
            "Charges Being Dismissed",
            "Factual Basis for Plea"
        ],
        "Sentencing Provisions": [
            "Agreed Upon Sentence or Sentencing Recommendation",
            "Maximum Possible Sentence",
            "Mandatory Minimum Sentence (if applicable)",
            "Restitution Agreement",
            "Forfeiture Provisions"
        ],
        "Waivers and Rights": [
            "Waiver of Right to Trial",
            "Waiver of Right to Appeal",
            "Consequences of Guilty Plea",
            "Immigration Consequences (if applicable)"
        ],
        "Additional Terms": [
            "Cooperation Agreement (if applicable)",
            "Conditions of Release Pending Sentencing",
            "Withdrawal of Plea Provisions",
            "Breach of Agreement Provisions"
        ],
        "Legal Formalities": [
            "Entire Agreement Clause",
            "Voluntary Nature of Plea Statement"
        ],
        "Signatures and Approval": [
            "Defendant's Signature",
            "Defense Attorney's Signature",
            "Prosecutor's Signature",
            "Date of Agreement",
            "Judge's Approval (if required)"
        ]
      }, 
      "Bail Applications":{
        "Case Identification": [
            "Case Number",
            "Court Name and Jurisdiction",
            "Defendant's Full Name",
            "Date of Arrest",
            "Charges",
            "Prosecutor's Name",
            "Defense Attorney's Name"
        ],
        "Defendant Information": [
            "Defendant's Date of Birth",
            "Defendant's Address",
            "Current Employment Status",
            "Length of Residence in Community",
            "Family Ties in Community"
        ],
        "Criminal History and Risk Assessment": [
            "Defendant's Criminal History",
            "Flight Risk Assessment",
            "Public Safety Risk Assessment",
            "Prior Failure to Appear (if any)",
            "Substance Abuse History (if relevant)",
            "Mental Health Status (if relevant)",
            "Passport Status"
        ],
        "Bail Proposal": [
            "Proposed Bail Amount",
            "Ability to Pay Bail",
            "Proposed Sureties (if any)",
            "Proposed Conditions of Release",
            "Proposed Reporting Requirements",
            "Electronic Monitoring Proposal (if applicable)",
            "Treatment Program Proposal (if applicable)"
        ],
        "Supporting Arguments": [
            "Arguments for Granting Bail",
            "Character References"
        ],
        "Signatures and Date": [
            "Defendant's Signature",
            "Defense Attorney's Signature",
            "Date of Application"
        ],
        "Additional Documentation": [
            "Supporting Documents List"
        ]
      }, 
      "Appeals":{
        "Case Identification": [
            "Case Number (Trial Court)",
            "Case Number (Appellate Court)",
            "Court of Appeal Name and Jurisdiction",
            "Trial Court Name and Jurisdiction",
            "Trial Judge's Name"
        ],
        "Party Information": [
            "Appellant's Name",
            "Appellee's Name",
            "Appellant's Attorney",
            "Appellee's Attorney"
        ],
        "Appeal Timeline": [
            "Date of Judgment or Order Being Appealed",
            "Notice of Appeal Filing Date",
            "Filing Date"
        ],
        "Case Background": [
            "Nature of the Case",
            "Summary of Trial Court Proceedings",
            "Statement of Facts"
        ],
        "Appeal Substance": [
            "Issues Presented for Review",
            "Standard of Review",
            "Summary of Argument",
            "Detailed Legal Arguments",
            "Relief Sought",
            "Preservation of Error"
        ],
        "Legal References": [
            "Relevant Statutes and Regulations",
            "Cited Case Law",
            "Record References"
        ],
        "Document Structure": [
            "Table of Contents",
            "Table of Authorities",
            "Conclusion"
        ],
        "Supplementary Materials": [
            "Appendix or Record Excerpts"
        ],
        "Procedural Requirements": [
            "Signature of Filing Attorney",
            "Certificate of Service",
            "Certificate of Compliance (e.g., word count)",
            "Oral Argument Request (if applicable)",
            "Proof of Filing"
        ]
      },
      "Divorce Petitions":{
        "PetitionerDetails": [
          "PetitionerFullName",
          "PetitionerDateOfBirth",
          "PetitionerAddress",
          "PetitionerPhoneNumber"
        ],
        "RespondentDetails": [
          "RespondentFullName",
          "RespondentDateOfBirth",
          "RespondentAddress",
          "RespondentPhoneNumber"
        ],
        "MarriageDetails": [
          "DateOfMarriage",
          "PlaceOfMarriage"
        ],
        "ChildrenDetails": [
          "ChildrenNamesAndAges",
          "CurrentCustodyArrangement"
        ],
        "GroundsForDivorce": [
          "ReasonForDivorce",
          "DetailsOfSeparation"
        ],
        "PropertyDivision": [
          "DetailsOfMaritalProperty"
        ],
        "SpousalSupport": [
          "RequestedSpousalSupport",
          "ReasonForRequestingSupport"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }, 
      "Child Custody Agreements":{
        "Parent1Details": [
          "Parent1FullName",
          "Parent1DateOfBirth",
          "Parent1Address",
          "Parent1PhoneNumber"
        ],
        "Parent2Details": [
          "Parent2FullName",
          "Parent2DateOfBirth",
          "Parent2Address",
          "Parent2PhoneNumber"
        ],
        "ChildDetails": [
          "ChildFullName",
          "ChildDateOfBirth"
        ],
        "CustodyDetails": [
          "CurrentCustodyArrangement",
          "DesiredCustodyArrangement",
          "VisitationSchedule",
          "HolidayAndVacationSchedule"
        ],
        "DecisionMakingDetails": [
          "EducationDecisions",
          "MedicalDecisions"
        ],
        "ChildSupport": [
          "ChildSupportAmount",
          "ReasonForCustodyRequest"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }, 
      "Prenuptial Agreements":{
        "Partner1Details": [
          "Partner1FullName",
          "Partner1DateOfBirth",
          "Partner1Address",
          "Partner1PhoneNumber"
        ],
        "Partner2Details": [
          "Partner2FullName",
          "Partner2DateOfBirth",
          "Partner2Address",
          "Partner2PhoneNumber"
        ],
        "MarriageDetails": [
          "DateOfMarriage",
          "PlaceOfMarriage"
        ],
        "PropertyDetails": [
          "PropertyOwnedByPartner1",
          "PropertyOwnedByPartner2"
        ],
        "AssetsDetails": [
          "AssetsOwnedByPartner1",
          "AssetsOwnedByPartner2"
        ],
        "DebtsDetails": [
          "DebtsOfPartner1",
          "DebtsOfPartner2"
        ],
        "IncomeDetails": [
          "IncomeOfPartner1",
          "IncomeOfPartner2"
        ],
        "AgreementTerms": [
          "SpousalSupportTerms",
          "InheritanceRights",
          "ReasonForPrenuptialAgreement"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }, 
      "Adoption Papers":{
        "AdoptiveParent1Details": [
          "AdoptiveParent1FullName",
          "AdoptiveParent1DateOfBirth",
          "AdoptiveParent1Address",
          "AdoptiveParent1PhoneNumber"
        ],
        "AdoptiveParent2Details": [
          "AdoptiveParent2FullName",
          "AdoptiveParent2DateOfBirth",
          "AdoptiveParent2Address",
          "AdoptiveParent2PhoneNumber"
        ],
        "ChildDetails": [
          "ChildFullName",
          "ChildDateOfBirth",
          "ChildPlaceOfBirth"
        ],
        "BiologicalParentDetails": [
          "BiologicalParentFullName",
          "BiologicalParentConsent"
        ],
        "AdoptionDetails": [
          "ReasonForAdoption",
          "AdoptionAgencyName",
          "AdoptionAgencyContact",
          "HomeStudyReportDetails",
          "DesiredAdoptionDate",
          "AdditionalNotes"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      ,
      "Demand Letters":{
        "SenderDetails": [
          "SenderFullName",
          "SenderAddress",
          "SenderPhoneNumber",
          "SenderEmail"
        ],
        "RecipientDetails": [
          "RecipientFullName",
          "RecipientAddress",
          "RecipientPhoneNumber",
          "RecipientEmail"
        ],
        "IncidentDetails": [
          "DateOfIncident",
          "IncidentDetails"
        ],
        "DemandDetails": [
          "AmountDemanded",
          "DeadlineForResponse",
          "LegalBasisForDemand",
          "ConsequencesOfNonCompliance",
          "AdditionalNotes"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Settlement Agreements":{
        "Party1Details": [
          "Party1FullName",
          "Party1Address",
          "Party1PhoneNumber",
          "Party1Email"
        ],
        "Party2Details": [
          "Party2FullName",
          "Party2Address",
          "Party2PhoneNumber",
          "Party2Email"
        ],
        "DisputeDetails": [
          "DateOfDispute",
          "DetailsOfDispute"
        ],
        "SettlementTerms": [
          "SettlementTerms",
          "PaymentAmount",
          "PaymentDueDate",
          "ConfidentialityClause",
          "NonDisparagementClause",
          "GoverningLaw",
          "AdditionalTerms"
        ],
        "AgreementFinalization": [
          "EffectiveDate",
          "Party1Signature",
          "Party2Signature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Complaints":{
        "ComplainantDetails": [
          "ComplainantFullName",
          "ComplainantAddress",
          "ComplainantPhoneNumber",
          "ComplainantEmail"
        ],
        "DefendantDetails": [
          "DefendantFullName",
          "DefendantAddress",
          "DefendantPhoneNumber",
          "DefendantEmail"
        ],
        "IncidentDetails": [
          "DateOfIncident",
          "LocationOfIncident",
          "DescriptionOfIncident"
        ],
        "ComplaintDetails": [
          "DamagesIncurred",
          "LegalBasisForComplaint",
          "ReliefSought",
          "AdditionalNotes"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Medical Release Forms":{
        "PatientDetails": [
          "PatientFullName",
          "PatientDateOfBirth",
          "PatientAddress",
          "PatientPhoneNumber",
          "PatientEmail"
        ],
        "EmergencyContactDetails": [
          "EmergencyContactFullName",
          "EmergencyContactPhoneNumber",
          "EmergencyContactRelationship"
        ],
        "MedicalProviderDetails": [
          "MedicalProviderFullName",
          "MedicalProviderAddress",
          "MedicalProviderPhoneNumber",
          "MedicalProviderEmail"
        ],
        "ReleaseDetails": [
          "PurposeOfRelease",
          "InformationToBeReleased",
          "ExpirationDateOfRelease"
        ],
        "Signatures": [
          "PatientSignature",
          "DateOfSignature",
          "WitnessFullName",
          "WitnessSignature",
          "WitnessDateOfSignature"
        ]
      }
      ,
      "Wills":{
        "TestatorDetails": [
          "TestatorFullName",
          "TestatorDateOfBirth",
          "TestatorAddress",
          "TestatorPhoneNumber",
          "TestatorEmail"
        ],
        "ExecutorDetails": [
          "ExecutorFullName",
          "ExecutorAddress",
          "ExecutorPhoneNumber",
          "ExecutorEmail"
        ],
        "BeneficiariesDetails": [
          "BeneficiariesNames",
          "BeneficiariesDetails"
        ],
        "EstateDetails": [
          "AssetsList",
          "SpecificBequests",
          "ResidueOfEstate",
          "GuardiansForMinorChildren",
          "SpecialInstructions"
        ],
        "Signatures": [
          "TestatorSignature",
          "DateOfSignature",
          "Witness1FullName",
          "Witness1Signature",
          "Witness1DateOfSignature",
          "Witness2FullName",
          "Witness2Signature",
          "Witness2DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Trusts":{
        "TrustorDetails": [
          "TrustorFullName",
          "TrustorDateOfBirth",
          "TrustorAddress",
          "TrustorPhoneNumber",
          "TrustorEmail"
        ],
        "TrusteeDetails": [
          "TrusteeFullName",
          "TrusteeAddress",
          "TrusteePhoneNumber",
          "TrusteeEmail"
        ],
        "BeneficiariesDetails": [
          "BeneficiariesNames",
          "BeneficiariesDetails"
        ],
        "TrustDetails": [
          "TrustProperty",
          "TermsOfTrust",
          "Revocability",
          "TrustDuration",
          "SpecialInstructions"
        ],
        "Signatures": [
          "TrustorSignature",
          "DateOfSignature",
          "WitnessFullName",
          "WitnessSignature",
          "WitnessDateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Power of Attorney":{
        "PrincipalDetails": [
          "PrincipalFullName",
          "PrincipalDateOfBirth",
          "PrincipalAddress",
          "PrincipalPhoneNumber",
          "PrincipalEmail"
        ],
        "AgentDetails": [
          "AgentFullName",
          "AgentAddress",
          "AgentPhoneNumber",
          "AgentEmail"
        ],
        "AuthorityDetails": [
          "ScopeOfAuthority",
          "EffectiveDate",
          "ExpirationDate",
          "SpecialInstructions"
        ],
        "Signatures": [
          "PrincipalSignature",
          "DateOfSignature",
          "WitnessFullName",
          "WitnessSignature",
          "WitnessDateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Living Wills":{
        "PrincipalDetails": [
          "PrincipalFullName",
          "PrincipalDateOfBirth",
          "PrincipalAddress",
          "PrincipalPhoneNumber",
          "PrincipalEmail"
        ],
        "HealthCareAgentDetails": [
          "HealthCareAgentFullName",
          "HealthCareAgentAddress",
          "HealthCareAgentPhoneNumber",
          "HealthCareAgentEmail"
        ],
        "Preferences": [
          "TreatmentPreferences",
          "OrganDonationPreferences",
          "EndOfLifeDecisions",
          "SpecialInstructions"
        ],
        "Signatures": [
          "PrincipalSignature",
          "DateOfSignature",
          "WitnessFullName",
          "WitnessSignature",
          "WitnessDateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      ,
      "Patent Applications":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "InventorDetails": [
          "InventorFullName",
          "InventorAddress",
          "InventorPhoneNumber",
          "InventorEmail"
        ],
        "InventionDetails": [
          "TitleOfInvention",
          "DescriptionOfInvention",
          "Claims",
          "Drawings",
          "FilingDate",
          "PatentType",
          "PriorityClaim",
          "PreviousApplications",
          "SpecialInstructions"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Trademark Registrations":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "TrademarkDetails": [
          "TrademarkName",
          "TrademarkDescription",
          "GoodsOrServices",
          "ClassOfGoodsOrServices",
          "FilingBasis",
          "FirstUseDate",
          "FirstUseInCommerceDate",
          "Specimen",
          "SpecialInstructions"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Copyright Registrations":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "AuthorDetails": [
          "AuthorFullName",
          "AuthorAddress",
          "AuthorPhoneNumber",
          "AuthorEmail"
        ],
        "WorkDetails": [
          "TitleOfWork",
          "TypeOfWork",
          "DateOfCreation",
          "DateOfPublication",
          "PublicationCountry",
          "PreviousRegistrations",
          "SpecialInstructions"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Licensing Agreements":{
        "LicensorDetails": [
          "LicensorFullName",
          "LicensorAddress",
          "LicensorPhoneNumber",
          "LicensorEmail"
        ],
        "LicenseeDetails": [
          "LicenseeFullName",
          "LicenseeAddress",
          "LicenseePhoneNumber",
          "LicenseeEmail"
        ],
        "LicenseDetails": [
          "LicenseDescription",
          "LicensedMaterial",
          "ScopeOfLicense",
          "Territory",
          "TermOfLicense",
          "RoyaltyTerms",
          "RenewalTerms",
          "ConfidentialityClause",
          "TerminationConditions",
          "SpecialInstructions"
        ],
        "Signatures": [
          "LicensorSignature",
          "LicenseeSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      ,
      "Employment Contracts":{
        "EmployerDetails": [
          "EmployerFullName",
          "EmployerAddress",
          "EmployerPhoneNumber",
          "EmployerEmail"
        ],
        "EmployeeDetails": [
          "EmployeeFullName",
          "EmployeeAddress",
          "EmployeePhoneNumber",
          "EmployeeEmail"
        ],
        "JobDetails": [
          "JobTitle",
          "JobDescription",
          "EmploymentStartDate",
          "EmploymentEndDate",
          "Salary",
          "Benefits",
          "WorkingHours",
          "LeavePolicies"
        ],
        "ContractTerms": [
          "ConfidentialityClause",
          "TerminationConditions",
          "SpecialInstructions"
        ],
        "Signatures": [
          "EmployerSignature",
          "EmployeeSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Severance Agreements":{
        "EmployerDetails": [
          "EmployerFullName",
          "EmployerAddress",
          "EmployerPhoneNumber",
          "EmployerEmail"
        ],
        "EmployeeDetails": [
          "EmployeeFullName",
          "EmployeeAddress",
          "EmployeePhoneNumber",
          "EmployeeEmail"
        ],
        "SeveranceDetails": [
          "TerminationDate",
          "SeverancePay",
          "BenefitsContinuation"
        ],
        "AgreementTerms": [
          "ConfidentialityClause",
          "NonCompeteClause",
          "NonSolicitationClause",
          "ReleaseOfClaims",
          "SpecialInstructions"
        ],
        "Signatures": [
          "EmployerSignature",
          "EmployeeSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Non-Compete Agreements":{
        "EmployerDetails": [
          "EmployerFullName",
          "EmployerAddress",
          "EmployerPhoneNumber",
          "EmployerEmail"
        ],
        "EmployeeDetails": [
          "EmployeeFullName",
          "EmployeeAddress",
          "EmployeePhoneNumber",
          "EmployeeEmail"
        ],
        "AgreementDetails": [
          "StartDate",
          "EndDate",
          "ScopeOfRestriction",
          "GeographicalLimitations",
          "ConfidentialityClause",
          "Consideration",
          "SpecialInstructions"
        ],
        "Signatures": [
          "EmployerSignature",
          "EmployeeSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      , 
      "Discrimination Complaints":{
        "ComplainantDetails": [
          "ComplainantFullName",
          "ComplainantAddress",
          "ComplainantPhoneNumber",
          "ComplainantEmail"
        ],
        "DefendantDetails": [
          "DefendantFullName",
          "DefendantAddress",
          "DefendantPhoneNumber",
          "DefendantEmail"
        ],
        "IncidentDetails": [
          "DateOfIncident",
          "LocationOfIncident",
          "DescriptionOfIncident",
          "TypeOfDiscrimination",
          "Witnesses",
          "Evidence"
        ],
        "ComplaintDetails": [
          "ReliefSought",
          "AdditionalNotes"
        ],
        "Signatures": [
          "ComplainantSignature",
          "DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      },
      "Tax Returns":{
        "TaxpayerDetails": [
          "TaxpayerFullName",
          "TaxpayerSSN",
          "TaxpayerAddress",
          "TaxpayerPhoneNumber",
          "TaxpayerEmail"
        ],
        "FilingDetails": [
          "FilingStatus",
          "DependentsNames",
          "DependentsSSNs",
          "DependentsDatesOfBirth"
        ],
        "IncomeDetails": [
          "IncomeDetails",
          "Deductions",
          "Credits",
          "TaxWithheld",
          "EstimatedTaxPayments"
        ],
        "TaxYearDetails": [
          "TaxYear",
          "AdditionalNotes"
        ],
        "Signatures": [
          "TaxpayerSignature",
          "DateOfSignature"
        ],
        "PreparerDetails": [
          "PreparerName",
          "PreparerFirm",
          "PreparerPhoneNumber",
          "PreparerEmail"
        ]
      }
      , 
      "IRS Appeals":{
        "AppellantDetails": [
          "AppellantFullName",
          "AppellantSSN",
          "AppellantAddress",
          "AppellantPhoneNumber",
          "AppellantEmail"
        ],
        "NoticeDetails": [
          "IRSNoticeNumber",
          "TaxYear",
          "DateOfIRSNotice"
        ],
        "AppealDetails": [
          "ReasonsForAppeal",
          "SupportingDocuments",
          "DesiredOutcome",
          "AdditionalNotes"
        ],
        "Signatures": [
          "AppellantSignature",
          "DateOfSignature"
        ],
        "RepresentativeDetails": [
          "RepresentativeName",
          "RepresentativeFirm",
          "RepresentativePhoneNumber",
          "RepresentativeEmail"
        ]
      }
      , 
      "Tax Opinion Letters":{
        "ClientDetails": [
          "ClientFullName",
          "ClientAddress",
          "ClientPhoneNumber",
          "ClientEmail"
        ],
        "IssueDetails": [
          "TaxIssue",
          "FactsOfCase",
          "RelevantTaxLaws",
          "Analysis",
          "Conclusion",
          "AdditionalNotes"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail",
          "DateOfLetter"
        ]
      }, 
      "Offshore Disclosure Forms":{
        "TaxpayerDetails": [
          "TaxpayerFullName",
          "TaxpayerSSN",
          "TaxpayerAddress",
          "TaxpayerPhoneNumber",
          "TaxpayerEmail"
        ],
        "AccountDetails": [
          "AccountHolderNames",
          "ForeignBankNames",
          "ForeignBankAddresses",
          "AccountNumbers",
          "AccountBalances",
          "IncomeFromForeignAccounts"
        ],
        "DisclosureDetails": [
          "TaxYear",
          "DisclosureReason",
          "SupportingDocuments",
          "AdditionalNotes"
        ],
        "Signatures": [
          "TaxpayerSignature",
          "DateOfSignature"
        ],
        "PreparerDetails": [
          "PreparerName",
          "PreparerFirm",
          "PreparerPhoneNumber",
          "PreparerEmail"
        ]
      }
      ,
      "Visa Applications":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantDateOfBirth",
          "ApplicantNationality",
          "ApplicantPassportNumber",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "VisaDetails": [
          "TypeOfVisa",
          "PurposeOfVisit",
          "IntendedDurationOfStay",
          "IntendedDateOfArrival",
          "PreviousVisaDetails",
          "SupportingDocuments",
          "AdditionalNotes"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "RepresentativeDetails": [
          "RepresentativeName",
          "RepresentativeFirm",
          "RepresentativePhoneNumber",
          "RepresentativeEmail"
        ]
      }
      , 
      "Green Card Applications":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantDateOfBirth",
          "ApplicantNationality",
          "ApplicantPassportNumber",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "VisaStatusDetails": [
          "CurrentVisaStatus",
          "EligibilityCategory",
          "SupportingDocuments",
          "AdditionalNotes"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "RepresentativeDetails": [
          "RepresentativeName",
          "RepresentativeFirm",
          "RepresentativePhoneNumber",
          "RepresentativeEmail"
        ]
      }
      , 
      "Citizenship Applications":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantDateOfBirth",
          "ApplicantNationality",
          "ApplicantPassportNumber",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "ResidenceDetails": [
          "GreenCardNumber",
          "DateOfPermanentResidence",
          "SupportingDocuments",
          "AdditionalNotes"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "RepresentativeDetails": [
          "RepresentativeName",
          "RepresentativeFirm",
          "RepresentativePhoneNumber",
          "RepresentativeEmail"
        ]
      }
      , 
      "Asylum Petitions":{
        "ApplicantDetails": [
          "ApplicantFullName",
          "ApplicantDateOfBirth",
          "ApplicantNationality",
          "ApplicantPassportNumber",
          "ApplicantAddress",
          "ApplicantPhoneNumber",
          "ApplicantEmail"
        ],
        "AsylumDetails": [
          "BasisForAsylum",
          "SupportingDocuments",
          "PersecutionDetails",
          "AdditionalNotes"
        ],
        "Signatures": [
          "ApplicantSignature",
          "DateOfSignature"
        ],
        "RepresentativeDetails": [
          "RepresentativeName",
          "RepresentativeFirm",
          "RepresentativePhoneNumber",
          "RepresentativeEmail"
        ]
      }
      ,
      "Purchase Agreements":{
        "BuyerDetails": [
          "BuyerFullName",
          "BuyerAddress",
          "BuyerPhoneNumber",
          "BuyerEmail"
        ],
        "SellerDetails": [
          "SellerFullName",
          "SellerAddress",
          "SellerPhoneNumber",
          "SellerEmail"
        ],
        "PropertyDetails": [
          "PropertyAddress",
          "PurchasePrice",
          "EarnestMoneyDeposit",
          "ClosingDate"
        ],
        "Contingencies": [
          "InspectionContingencies",
          "FinancingContingencies",
          "AdditionalTerms"
        ],
        "Signatures": [
          "BuyerSignature",
          "SellerSignature",
          "DateOfSignature"
        ],
        "AgentDetails": [
          "AgentName",
          "AgentFirm",
          "AgentPhoneNumber",
          "AgentEmail"
        ]
      }
      , 
      "Lease Agreements":{
        "LandlordDetails": [
          "LandlordFullName",
          "LandlordAddress",
          "LandlordPhoneNumber",
          "LandlordEmail"
        ],
        "TenantDetails": [
          "TenantFullName",
          "TenantAddress",
          "TenantPhoneNumber",
          "TenantEmail"
        ],
        "PropertyDetails": [
          "PropertyAddress",
          "LeaseStartDate",
          "LeaseEndDate",
          "RentAmount",
          "SecurityDeposit"
        ],
        "Responsibilities": [
          "MaintenanceResponsibilities",
          "UtilitiesResponsibilities",
          "AdditionalTerms"
        ],
        "Signatures": [
          "LandlordSignature",
          "TenantSignature",
          "DateOfSignature"
        ],
        "AgentDetails": [
          "AgentName",
          "AgentFirm",
          "AgentPhoneNumber",
          "AgentEmail"
        ]
      }
      , 
      "Mortgage Documents":{
        "BorrowerDetails": [
          "BorrowerFullName",
          "BorrowerAddress",
          "BorrowerPhoneNumber",
          "BorrowerEmail"
        ],
        "LenderDetails": [
          "LenderFullName",
          "LenderAddress",
          "LenderPhoneNumber",
          "LenderEmail"
        ],
        "PropertyDetails": [
          "PropertyAddress",
          "LoanAmount",
          "InterestRate",
          "LoanTerm",
          "MonthlyPayment",
          "StartDate",
          "EndDate"
        ],
        "LoanTerms": [
          "PrepaymentPenalties",
          "InsuranceRequirements",
          "AdditionalTerms"
        ],
        "Signatures": [
          "BorrowerSignature",
          "LenderSignature",
          "DateOfSignature"
        ],
        "AgentDetails": [
          "AgentName",
          "AgentFirm",
          "AgentPhoneNumber",
          "AgentEmail"
        ]
      }
      , 
      "Property Deeds":{
        "GrantorDetails": [
          "GrantorFullName",
          "GrantorAddress",
          "GrantorPhoneNumber",
          "GrantorEmail"
        ],
        "GranteeDetails": [
          "GranteeFullName",
          "GranteeAddress",
          "GranteePhoneNumber",
          "GranteeEmail"
        ],
        "PropertyDetails": [
          "PropertyAddress",
          "LegalDescription",
          "SalePrice",
          "DateOfTransfer",
          "SpecialConditions"
        ],
        "Signatures": [
          "GrantorSignature",
          "GranteeSignature",
          "DateOfSignature"
        ],
        "WitnessDetails": [
          "Witness1FullName",
          "Witness1Signature",
          "Witness1DateOfSignature",
          "Witness2FullName",
          "Witness2Signature",
          "Witness2DateOfSignature"
        ],
        "LegalRepresentative": [
          "LawyerName",
          "LawyerFirm",
          "LawyerPhoneNumber",
          "LawyerEmail"
        ]
      }
      ,
  };
